import { createSlice } from '@reduxjs/toolkit';
import localStorage from 'react-secure-storage';

const MENU_STATUS_STORAGE_KEY = 'menuStatus.opened';

interface MenuState {
  opened: boolean;
  searchKeyword: string;
  loading: boolean;
  error: string | null;
}

const initialState: MenuState = {
  opened: localStorage.getItem(MENU_STATUS_STORAGE_KEY) === true || false,
  searchKeyword: '',
  loading: false,
  error: null,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.opened = !state.opened;
      localStorage.setItem(MENU_STATUS_STORAGE_KEY, state.opened);
    },
    openMenu: (state) => {
      state.opened = true;
      localStorage.setItem(MENU_STATUS_STORAGE_KEY, state.opened);
    },
    closeMenu: (state) => {
      state.opened = false;
      localStorage.setItem(MENU_STATUS_STORAGE_KEY, state.opened);
    },
  },
});

export const { toggleMenu, openMenu, closeMenu } = menuSlice.actions;

export default menuSlice.reducer;
