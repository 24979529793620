/* eslint-disable max-len */
import { createSlice, createAsyncThunk, type PayloadAction } from '@reduxjs/toolkit';
import { auth, functions } from 'helpers/Firebase';
import { adminRoot, currentUser } from 'constants/defaultValues';
import { getAuth, sendEmailVerification, signInWithEmailAndPassword, type UserCredential } from 'firebase/auth';
import { getUserInfo } from 'helpers/firebaseHelpers';
import { type HttpsCallableResult } from 'firebase/functions';
import type { History } from 'history';
import type { AnyUser } from 'types';
import type { HomeownerUser } from 'types/HomeownerUser';
import type { HoaUser } from 'types/HoaUser';
import type { ProviderUser } from 'types/ProviderUser';
import { type DocumentData } from 'firebase/firestore';

const createProviderUser = functions.httpsCallable('createProviderUser');
const createHomeownerUser = functions.httpsCallable('createHomeownerUser');

interface LoginCredentials {
  email: string;
  password: string;
  history: History;
}

interface RegisterHomeownerCredentials extends LoginCredentials {
  firstName: string;
  lastName: string;
  personalCode: string;
}

interface RegisterProviderCredentials extends LoginCredentials {
  name: string;
  personalCode: string;
}

interface ForgotPasswordCredentials {
  forgotUserMail: { email: string };
}

interface ResetPasswordCredentials {
  newPassword: string;
  resetPasswordCode: string;
}

interface AuthState {
  currentUser: AnyUser | null;
  loading: boolean;
  error: string | null;
}

const createHomeownerUserObject = (uid: string, email: string, data: DocumentData): HomeownerUser => ({
  ...currentUser,
  uid,
  email: email ?? '',
  img: `https://firebasestorage.googleapis.com/v0/b/thermokracy-dev.appspot.com/o/ProfilePictures%2F${uid}.jpg?alt=media`,
  ...data,
  myProviders: data.myProviders ?? [],
  myProvidersDeleted: data.myProvidersDeleted ?? [],
  community: data.community ?? '',
  CreationDate: data.creationDate ?? '',
  address: data.address ?? '',
  name: data.name ?? '',
  phone: data.phone ?? '',
});

const createProviderUserObject = (uid: string, email: string, data: any): ProviderUser => ({
  ...currentUser,
  uid,
  email: email ?? '',
  img: `https://firebasestorage.googleapis.com/v0/b/thermokracy-dev.appspot.com/o/ProfilePictures%2F${uid}.jpg?alt=media`,
  ...data,
  community: data.community ?? '',
  CreationDate: data.creationDate ?? '',
  address: data.address ?? '',
  name: data.name ?? '',
  number: data.number ?? '',
  phone: data.phone ?? '',
  photo: data.photo ?? '',
  engagedCommunities: data.activeCommunities ?? [],
  services: data.services ?? [],
  temperature: data.temperature ?? '',
});

const createHoaUserObject = (uid: string, email: string, data: any): HoaUser => ({
  ...currentUser,
  uid,
  email: email ?? '',
  img: `https://firebasestorage.googleapis.com/v0/b/thermokracy-dev.appspot.com/o/ProfilePictures%2F${uid}.jpg?alt=media`,
  ...data,
  CreationDate: data.creationDate ?? '',
  address: data.address ?? '',
  name: data.name ?? '',
  number: data.number ?? '',
  phone: data.phone ?? '',
  photo: data.photo ?? '',
});

export function createUserObject(uid: string, email: string, data: DocumentData): AnyUser {
  switch (data?.role) {
    case 1:
      return createHomeownerUserObject(uid, email, data);
    case 2:
      return createProviderUserObject(uid, email, data);
    case 3:
      return createHoaUserObject(uid, email, data);
    default:
      throw new Error('Invalid user role');
  }
}

export const loginWithEmailPassword = createAsyncThunk<{ user: AnyUser }, LoginCredentials, { rejectValue: string }>(
  'auth/loginWithEmailPassword',
  async ({ email, password, history }, { rejectWithValue }) => {
    try {
      const loginUser: UserCredential = await signInWithEmailAndPassword(auth, email, password);
      if (loginUser.user !== null) {
        const userInfo = await getUserInfo(loginUser.user.uid);
        const data: DocumentData = userInfo?.data() ?? {};
        let userObject: AnyUser;

        try {
          userObject = createUserObject(loginUser.user.uid, loginUser.user.email ?? '', data);
        } catch (error) {
          return rejectWithValue((error as Error).message);
        }
        history.push(adminRoot);
        return { user: userObject };
      }
      return rejectWithValue('Login failed');
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const registerHomeownerWithEmailPassword = createAsyncThunk<
  { user: HomeownerUser },
  RegisterHomeownerCredentials,
  { rejectValue: string }
>('auth/registerUser', async ({ email, password, firstName, lastName, personalCode, history }, { rejectWithValue }) => {
  try {
    const registeredUserResponse: HttpsCallableResult = await createHomeownerUser({
      email,
      password,
      firstName,
      lastName,
      personalCode,
    });

    // Handle errors from the backend function
    if ('error' in registeredUserResponse) {
      return rejectWithValue(registeredUserResponse.error as string);
    }

    // Register user and handle authentication
    const loginUser: UserCredential = await signInWithEmailAndPassword(auth, email, password);
    if (loginUser.user != null) {
      const userInfo = await getUserInfo(loginUser.user.uid);
      const data: DocumentData = userInfo?.data() ?? {};
      const userObject = createHomeownerUserObject(loginUser.user.uid, loginUser.user.email ?? '', data);

      const authInstance = getAuth();
      if (authInstance.currentUser !== null) {
        await sendEmailVerification(authInstance.currentUser);
      }
      history.push(adminRoot);
      return { user: userObject };
    }

    // Handle authentication errors
    return rejectWithValue('Authentication failed');
  } catch (error) {
    // Handle other errors
    return rejectWithValue((error as Error).message);
  }
});

export const registerProviderWithEmailPassword = createAsyncThunk<
  { user: ProviderUser },
  RegisterProviderCredentials,
  { rejectValue: string }
>(
  'auth/registerProviderWithEmailPassword',
  async ({ email, password, name, personalCode, history }, { rejectWithValue }) => {
    try {
      const registeredUser: HttpsCallableResult = await createProviderUser({
        email,
        password,
        name,
        personalCode,
      });
      // Check the HTTP status code of the response
      if ('status' in registeredUser && registeredUser.status === 400) {
        // Handle the 400 Bad Request response here
        return rejectWithValue('Bad Request');
      }
      const loginUser: UserCredential = await signInWithEmailAndPassword(auth, email, password);
      if (loginUser.user !== null) {
        const userInfo = await getUserInfo(loginUser.user.uid);
        const data: DocumentData = userInfo?.data() ?? {};
        const userObject = createProviderUserObject(loginUser.user.uid, loginUser.user.email ?? '', data);
        const authInstance = getAuth();
        if (authInstance.currentUser !== null) {
          await sendEmailVerification(authInstance.currentUser);
        }
        history.push(adminRoot);
        return { user: userObject };
      }

      return rejectWithValue('Login failed');
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const logoutUser = createAsyncThunk<undefined, { history: History }>('auth/logoutUser', async ({ history }) => {
  await auth.signOut();
  history.push(adminRoot);
  return undefined; // Explicitly return undefined
});

export const forgotPassword = createAsyncThunk<string, ForgotPasswordCredentials, { rejectValue: string }>(
  'auth/forgotPassword',
  async ({ forgotUserMail }, { rejectWithValue }) => {
    try {
      await auth.sendPasswordResetEmail(forgotUserMail.email);
      return 'success';
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const resetPassword = createAsyncThunk<string, ResetPasswordCredentials, { rejectValue: string }>(
  'auth/resetPassword',
  async ({ newPassword, resetPasswordCode }, { rejectWithValue }) => {
    try {
      await auth.confirmPasswordReset(resetPasswordCode, newPassword);
      return 'success';
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

const initialState: AuthState = {
  currentUser: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setReduxUser(state, action: PayloadAction<AnyUser | null>) {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginWithEmailPassword.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(loginWithEmailPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload.user;
      })
      .addCase(loginWithEmailPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? 'An error occurred';
      })
      .addCase(registerProviderWithEmailPassword.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(registerProviderWithEmailPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload.user;
      })
      .addCase(registerProviderWithEmailPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? 'An error occurred';
      })
      .addCase(registerHomeownerWithEmailPassword.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(registerHomeownerWithEmailPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload.user;
      })
      .addCase(registerHomeownerWithEmailPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? 'An error occurred';
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.currentUser = null;
      });
  },
});

export const clearError = createAsyncThunk('auth/clearError', async (_, { dispatch }) => {
  dispatch(authSlice.actions.setReduxUser(null));
});

export default authSlice.reducer;
