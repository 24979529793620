import { defaultLocale, localeOptions } from 'constants/defaultValues';
import { defaultErrorHandler } from './errorHandlingHelper';

/**
 * Orders the array in-place based on the order of the keys in the order array
 *
 * @param items
 * @param order
 * @param key
 * @returns a reference to items parameter
 */
export const mapOrder = function mapOrder(
  items: Array<Record<string, string>>,
  order: string[],
  key: string,
): Array<Record<string, string>> {
  return items.sort((a, b) => order.indexOf(a[key]) - order.indexOf(b[key]));
};

export const getDateWithFormat = function getDateWithFormat(): string {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = String(today.getFullYear()).padStart(4, '0');

  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = function getCurrentTime(): string {
  const now = new Date();
  const hours = String(now.getHours());
  const mins = String(now.getMinutes()).padStart(2, '0');
  return `${hours}:${mins}`;
};

export const getCurrentLanguage = function getCurrentLanguage(): string {
  let language = defaultLocale;
  try {
    const storageValue = localStorage.getItem('currentLanguage') ?? defaultLocale;
    language = localeOptions.some((locale) => locale.id === storageValue) ? storageValue : defaultLocale;
  } catch (error) {
    defaultErrorHandler('>>>>: helpers/utils : getCurrentLanguage -> error', error as Error);
  }
  return language;
};
export const setCurrentLanguage = function setCurrentLanguage(locale: string): void {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    defaultErrorHandler('>>>>: helpers/utils : setCurrentLanguage -> error', error as Error);
  }
};

/**
 * Normalize text for search (removes accents and special characters, and lowercases the text)
 */
export function normalizeText(text: string): string {
  return text.toLowerCase().normalize('NFKD').replace(/[^\w]/g, '');
}
